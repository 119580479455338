<style scoped="">
.central-text-field >>> .v-messages__message {
  color: #FFFF00 !important;
  caret-color: #FFFF00 !important;
  font-size: 16px;
}
</style>
<template>
  <div>
    <section class="show_elements">

      <!--
       <v-item-group multiple="">
           <v-card class="d-flex justify-start mb-6" flat="" tile="">
               <h4 class="pt-5">
                   Views:
               </h4>
               <v-checkbox :label="view_params.abstract.label" class="pl-3" v-model="view_params.abstract.visible">
               </v-checkbox>
               <v-checkbox :label="view_params.doi.label" class="pl-3" v-model="view_params.doi.visible">
               </v-checkbox>
               <v-checkbox :label="view_params.author.label" class="pl-3" v-model="view_params.author.visible">
               </v-checkbox>
               <v-checkbox :label="view_params.institutions.label" class="pl-3" v-model="view_params.institutions.visible">
               </v-checkbox>
               <v-checkbox :label="view_params.links.label" class="pl-3" v-model="view_params.links.visible">
               </v-checkbox>
               <v-checkbox :label="view_params.link_to_pdf.label" class="pl-3" v-model="view_params.link_to_pdf.visible">
               </v-checkbox>
               <v-checkbox :label="view_params.link_to_md.label" class="pl-3" v-model="view_params.link_to_md.visible">
               </v-checkbox>
           </v-card>
       </v-item-group>
       -->
    </section>

    <section class="posts pt-6" v-if="typeof posts != 'undefined' && posts.length">
      <div>
        <div v-if="count>0">
        <!--  <b class="pa-2 pl-3">Journals added:</b>{{ count }} -->
        </div>
        <template>
          <div :key="post.id" class="article d-flex pa-1 pt-5 flex-column" v-for="(post, index) in posts">
            <Article :index="index" :post="post" :view_params="view_params">
            </Article>
          </div>
        </template>
      </div>
    </section>
    <section v-if="loading">
      <div>
        <template>
          <div class="text-center">

            <v-dialog
                v-model="dialog"
                persistent
                width="300"
            >
              <v-card
                  color="#206297"
                  dark
              >
                <v-card-text>
                  Please stand by
                  <v-progress-linear
                      indeterminate
                      color="white"
                      class="mb-0"
                  ></v-progress-linear>
                </v-card-text>
              </v-card>
            </v-dialog>
          </div>
        </template>
      </div>
    </section>
    <div class="bottom">
    </div>
    <!-- <back-to-top/> -->
  </div>
</template>
<script>
import Article from "@/components/home/Article";
import ViewParams from "@/mixins/ViewParams";

export default {
  data() {
    return {
      loading: false,
      dialog: true,
      count: 0,
      noNewResults: false,
      noAnyResults: false,
      counter: 0,
      posts: [],
      view_params: null,
      saved_articles: [],
      error: null
    }
  },
  created() {
    this.view_params = ViewParams.view_params;
    this.getPosts();
  },
  computed: {
    userLogin: function () {

      return this.$store.state.authentication.status.loggedIn;
    },
  },
  components: {
    Article
  },
  mounted() {
    this.scroll();
    this.$root.$off("load_result_data");
    this.$root.$on("load_result_data", () => {
      if (!this.$store.state.search.startSearch) {
        this.loadData();
      }
    });
  },
  mixins: [ViewParams],
  watch: {
    $route: 'getPosts'
  },
  methods: {
    getPosts() {
      this.error = null
      if (this.loading) {
        return;
      }
      this.loadData();
    },

    _prepareSubjects(posts) {
      var subjects = [];
      Object.keys(posts).forEach(function (key) {

        if (posts[key]['subjects'].length > 0) {
          Object.keys(posts[key]['subjects']).forEach(function (key2) {
            subjects[posts[key]['subjects'][key2]['id']] = posts[key]['subjects'][key2]['name'];
          });
        }

      });

      return subjects;

    },

    loadData() {
      this.loading = true;
      this.$store.dispatch('search/getSearchData')
          .then((data) => {
            // con
            // if (typeof data['filter'] !== "undefined") {
            //   console.log("data['filter']", data['filter']);
            // }
            if (typeof data !== "undefined") {

              if (data.post.length > 0) {
                this.loading = false;
                this.counter = 0;
                this.posts = data.post;
                this.count = data.count;
              //  let subjects = this._prepareSubjects(data.post);


              //  this.$root.$emit("set_filter_subjects", subjects);
              } else {
                if (this.counter < 3) {
                  this.counter++;
                  setTimeout(() => {
                    this.loadData()
                  }, 5000);
                } else {
                  this.loading = false;
                  if (this.posts.length > 0) {
                    this.$root.$emit("show_notification_modal", {
                      'title': "No new result",
                      "body": "<strong>No new result found for your query</strong>"
                    });
                    this.noNewResults = true;
                  } else {
                    this.noAnyResults = true;
                  }
                }
              }
            }
          });
    },

    isScrolledIntoView(el) {
      let rect = el.getBoundingClientRect()
      let elemTop = rect.top
      let elemBottom = rect.bottom
      let isVisible = elemTop < window.innerHeight && elemBottom >= 0
      return isVisible
    },

    scroll() {
      window.onscroll = () => {
        let scrolledTo = document.querySelector('.bottom')

        if (scrolledTo && this.isScrolledIntoView(scrolledTo)) {
          this.getPosts()
        }
      }
    }
  }
}
</script>