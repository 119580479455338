<template>   
    <v-col  class='d-flex justify-end' style="padding: 0;">
        <router-link v-if="userLogin" to="/dashboard" class="routerLink pr-0 pr-sm-1">
          <v-icon color="#FFF">
             mdi-view-dashboard-outline
          </v-icon>
          Dashboard
        </router-link>
    </v-col>
</template>
<script>
   export default {
    data: () => ({
      href : '',
    }),
    mounted() {
      this.href = process.env.VUE_APP_SERVER_HOST;
    },
    computed: {
      userLogin: function () {
        return this.$store.state.authentication.status.loggedIn;
      },
    }
  }
</script>