<template>
  <v-container fluid="">
    
    <SearchFilter></SearchFilter>
    <v-row
      >
        
      <v-col cols="auto" class="flex-grow-1 flex-shrink-1">
      <v-app>
        <HeadText type="SearchResultsPage">
        </HeadText>
        <v-main>
          <v-row class="pl-1 pl-md-12" no-gutters="">
            <v-col cols="12" md="8" lg="10">
              <v-row>
                <v-col>
                  <SearchForm :SearchResultsPage="true"></SearchForm>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div id="div-gpt-ad-1651756287381-0"></div>                
                  <div id="div-gpt-ad-1651756349185-0"></div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <SearchResults></SearchResults>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="0" sm="0" md="4" lg="2">
              <div style="max-width: 310px; width: 310px" class="pt-16" id="ads">
                <div id="div-gpt-ad-1651757382671-0"></div>                
                <div id="div-gpt-ad-1651756050444-0"></div>                
                <div id="div-gpt-ad-1651755932325-0"></div>                
                <div id="div-gpt-ad-1651756215424-0"></div>                
              </div>
              <SearchProgress></SearchProgress>
            </v-col>
          </v-row>
        </v-main>
      </v-app>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ADSMixin from "@/mixins/ADSMixin";
import HeadText from "../components/home/HomeAppBar";
import SearchForm from "../components/home/SearchForm";
import SearchResults from "../components/home/SearchResults";
import SearchProgress from "../components/search/SearchProgress";
import SearchFilter from "@/components/search/SearchFilter";

export default {
  name: 'Search',
  mixins:[ADSMixin],
  components: {
    SearchFilter,
    HeadText,
    SearchForm,
    SearchResults,
    SearchProgress,
  },

  data: () => ({
    //
  }),
  mounted() {
    this.initAds();  
  }
};
</script>
