<template>


  <v-dialog
      v-if="!userLogin"
      persistent
      max-width="500"
      v-model="setNewPasswordDialog"
  >


    <template>
      <v-card>
        <form style="width: 100%" ref="setNewPassword" method="post"
              @submit.prevent="setNewPassword($data)">

          <v-toolbar
              color="#68b7df"
              dark
          >Set New Password
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-overlay :value="$store.state.overlay.show" :absolute="absolute">
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>
              </v-overlay>
              <v-row>


                <v-col cols="12">
                  <v-text-field
                      type="password"
                      label="Password*"
                      v-model="password"
                      :error-messages="passwordErrors"
                      @input="$v.password.$touch()"
                      name="password"

                  ></v-text-field>
                  <div v-if="alert.validationErrors && alert.validationErrors['changeownpasswordform-password']"
                       :class="`alert ${alert.type}`">{{ alert.validationErrors['changeownpasswordform-password'][0] }}
                  </div>
                </v-col>


              </v-row>
              <v-row>


                <v-col cols="12">
                  <v-text-field
                      type="password"
                      label="Repeat Password*"
                      v-model="repeat_password"
                      :error-messages="password2Errors"
                      @input="$v.repeat_password.$touch()"
                      name="repeat_password"

                  ></v-text-field>
                  <div v-if="alert.validationErrors && alert.validationErrors['changeownpasswordform-repeat_password']"
                       :class="`alert ${alert.type}`">
                    {{ alert.validationErrors['changeownpasswordform-repeat_password'][0] }}
                  </div>
                </v-col>

                <div v-if="alert.message" :class="`alert ${alert.type}`">{{ alert.message }}</div>

              </v-row>
            </v-container>


          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
                text
                @click=goToHome()
            >Close
            </v-btn>

            <v-btn

                color="blue darken-1"
                text
                type="submit"
            >Send
            </v-btn>


          </v-card-actions>
        </form>
      </v-card>
    </template>
  </v-dialog>

</template>
<script>
import {validationMixin} from "vuelidate";
import {minLength, required, sameAs} from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  validations: {
    password: {required, minLength: minLength(6)},
    repeat_password: {required, sameAsPassword: sameAs('password')},


  },
  name: "SetNewPasswordDialogOld",
  data: () => ({
    setNewPasswordDialog: false,
    overlay: false,
    absolute: true,
    password: '',
    repeat_password: '',
    token: '',
    error: false,
  }),
  mounted() {
    this.$root.$on("show_set_new_password_dialog", () => {
      this.setNewPasswordDialog = true;
    });
    this.$root.$on("close_set_new_password_dialog", () => {
      this.$router.push('/');
      this.setNewPasswordDialog = true;
    })
  },
  computed: {


    alert() {
      return this.$store.state.alert
    },
    userLogin: function () {
      return this.$store.state.authentication.status.loggedIn;
    },

    // changePasswordSuccess: function () {
    //   return this.$store.state.authentication.status.changePasswordSuccess;
    // },

    passwordErrors() {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push('Password field is required.')
      !this.$v.password.minLength && errors.push('Password to short.')

      return errors
    },

    password2Errors() {
      const errors = []
      if (!this.$v.repeat_password.$dirty) return errors
      !this.$v.repeat_password.required && errors.push('Repeat password field is required.')
      !this.$v.repeat_password.sameAsPassword && errors.push('Passwords do not match')

      return errors
    },

  },

  methods: {
    goToHome: function () {
      this.$router.push('/');
      this.setNewPasswordDialog = false;
    },
    setNewPassword: function (payload) {
      payload.token = this.$route.query.token;
      // console.log(payload);
      this.$v.$touch()
      if (this.$v.$pending || this.$v.$error) return;
      this.$store.dispatch('overlay/show');
      // // // this.overlay = true
      // // // userService.login(payload).finally(() => this.overlay = false);
      const {password, repeat_password} = this;
      // //
      if (password && repeat_password) {
        this.$store.dispatch('alert/clear');
        this.$store.dispatch('authentication/setNewPassword', payload).then(
            response => {
              if (response.result) {
                let body = this.$route.query.registration != undefined ? '<strong>Your registration has been completed successfully</strong>' : '<strong>Your password has been successfully changed</strong>'
                this.$router.push('/');
                this.setNewPasswordDialog = false;
                this.$root.$emit("show_notification_modal", {
                  'title': "Set password successful ",
                  "body": body
                });
              }

            }
        )
      }
      // this.$refs.setNewPassword.submit()

      this.overlay = true
    },
    // logout:function (){
    //   this.$store.dispatch('alert/clear');
    //   this.$store.dispatch('authentication/logout');
    //   this.$user.set({ role: "guest" });
    // }
  },
  watch: {
    // changePasswordSuccess(newValue) {
    //   console.log(newValue);
    //   if (newValue === true) {
    //     this.$router.push('/');
    //     this.setNewPasswordDialog = false;
    //   }
    // }
  }
}
</script>