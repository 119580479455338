export default {
	
			view_params: {
				'abstract': {
					label: 'Abstract',
					visible: true
				},
				'doi': {
					label: 'DOI',
					visible: true
				},
				'author': {
					label: 'Author(s)',
					visible: true
				},
				'links': {
					label: 'Links details',
					visible: true
				},
				'publish_date': {
					label: 'Publish date',
					visible: true
				},
				'institutions': {
					label: 'Institution(s)',
					visible: true
				},
				'key_words': {
					label: 'Key words',
					visible: true
				},
				'link_to_pdf': {
					label: 'Link to PDF',
					visible: true
				},
				'link_to_md': {
					label: 'Direct link to meta data',
					visible: true
				}
			},
		
};