<template>
    <v-col class="pa-0 pl-2" cols="auto">
        <v-row class="article_header"
      no-gutters
      style="flex-wrap: nowrap;"
    >
            <div class="article_title justify-space-between ">
                <a target="_blank" v-bind:href="(prepareLink(post.links))?prepareLink(post.links):'https://doi.org/'+ post.doi">
                    {{ post.title }}
                </a>
            </div>
            <div class="article_icons  flex-grow-0 pa-0 d-flex justify-space-between">
                <v-row no-gutters>
                   <div>
                        <template v-if="userLogin">
                            <v-tooltip top v-if="typeof(saved_articles.articles[post.id]) == 'undefined'" color="#EE7100">
                                <template  v-slot:activator="{ on, attrs }">
                                    <v-btn @click.stop.prevent="saveArticle(post)" icon="" v-bind="attrs" v-on="on">
                                        <v-icon color="#EE7100">
                                            mdi-bookmark-outline
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>
                                    Save article
                                </span>
                            </v-tooltip>
                            <v-tooltip top v-if="typeof(saved_articles.articles[post.id]) != 'undefined'" color="#EE7100">
                                <template  v-slot:activator="{ on, attrs}">
                                    <v-btn @click.stop.prevent="removeArticle(saved_articles.articles[post.id].id, post.id)" icon="" v-bind="attrs" v-on="on">
                                        <v-icon color="#EE7100">
                                            mdi-bookmark
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>
                                    Saved article
                                </span>
                            </v-tooltip>
                        </template>
                        <template v-if="!userLogin">
                            
                                  <v-tooltip top color="#EE7100">
                                      <template  v-slot:activator="{ on, attrs }">
                                          <v-btn icon v-bind="attrs" v-on="on" @click.stop="dialog = true">
                                              <v-icon color="#EE7100">
                                                 mdi-bookmark-outline    
                                              </v-icon>
                                          </v-btn>
                                          <v-dialog
                                            v-model="dialog"
                                            persistent
                                            max-width="600px"
                                          >
                                          <v-card>
                                               <v-card-title>
                                                  <span class="text-h5">Save article</span>
                                                </v-card-title>
                                              <v-card-text >
                                                <v-overlay :value="$store.state.overlay.show" :absolute="true">
                                                  <v-progress-circular
                                                      indeterminate
                                                      size="64"
                                                  ></v-progress-circular>
                                                </v-overlay>
                                                
                                                <v-container>
                                                   <v-row>
                                                     <v-col cols="12"> 
                                                      Please log in/register to use this function
                                                     </v-col>
                                                   </v-row>
                                                </v-container>
                                              </v-card-text>
                                              <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    color="blue darken-1"
                                                    text
                                                    @click="dialog = false"
                                                >
                                                  Close
                                                </v-btn>
                                                
                                                <v-btn
                                                    color="blue darken-1"
                                                    text
                                                    to="/login" 
                                                >
                                                  Log in/Register
                                                </v-btn>
                                              </v-card-actions>
                                            </v-card>
                                          </v-dialog>
                                      </template>
                                      <span>
                                          Save article
                                      </span>
                                  </v-tooltip>
                                
                          </template>
                      </div>
                      <div class="d-flex pt-2">
                        
                        <v-tooltip top="" color="#EE7100">
                            <template  v-slot:activator="{ on, attrs }">
                              <a href="https://creativecommons.org/about/cclicenses/" target="_blanck" v-bind="attrs" v-on="on">
                                  <v-img height="20px" max-width="13px" v-bind:src="require('@/assets/images/score.png')" width="15px">
                                  </v-img>
                              </a>
                            </template>
                            <span>
                               This article is open access
                            </span>
                        </v-tooltip>
                        
                    </div>
                   </v-row> 
            </div>
           
        </v-row>
        <v-row>
          <div class="article_abstract pb-0 col" v-if="post.authors.length != 0">
                {{ post.abstract }}
            </div>  
        </v-row>

          
        <v-row> 
          <v-col cols="7" sm="8" class="d-block text-truncate article_authors mt-3">
              {{ formatAuthors(post.authors) }}  
          </v-col>
          <v-col cols="5" sm="4" v-if="prepareLink(post.links)">
            <v-btn @click.stop.prevent="copyLink(prepareLink(post.links))" color="indigo" outlined="">
                    Copy link 

                </v-btn>
          </v-col>
        </v-row>
        <v-row class="tech_info"> 
            <v-col cols="auto" class="article_journal d-flex align-start" v-if="post.services[0]">
                <v-img height="14px" max-width="14px" v-bind:src="require('@/assets/images/journal.png')" width="14px">
                              </v-img> {{ post.services[0].name }}
            </v-col>
            
            <v-col cols="auto" class="d-flex align-start" v-if="post.month && post.year ">
                Published: {{ post.month }} {{ post.year }} 
            </v-col>
            <v-col cols="auto" class="d-flex align-start" v-if="post.pdfLink && view_params.link_to_pdf.visible">
                <a target="_blank" v-bind:href="post.pdfLink.url" class="dwnl_dpf">
                      Download PDF
                  
              </a>
            </v-col>
            <v-col cols="auto" class="d-flex align-start" v-if="post.journals[0]">
                Journal: <a target="_blank" v-bind:href="'https://doi.org/'+ post.doi" class="pl-1">
                          {{ post.journals[0].name }}
                      </a>
            </v-col>
            <v-col cols="auto" class="d-flex align-start" v-if="post.doi" >
                DOI:  {{ post.doi}}
            </v-col>
          </v-row>
        
            <hr/>
        
    </v-col>
</template>
<script>
    import PrepareAuthorsMixin from "@/mixins/PrepareAuthorsMixin";
    import SaveArticleMixin from "@/mixins/SaveArticleMixin";

    export default {
  name: 'ArticleView',
  mixins: [PrepareAuthorsMixin, SaveArticleMixin],
  data() {
    return {
      score: '',
      dialog: false,
    }
  },
  computed: {
    userLogin: function () {
      return this.$store.state.authentication.status.loggedIn;
    },
  },
  props: {
    post: Object,
    index: Number,
    view_params: Object
  },
  created() {
    //this.calculateScore();
    //this.saved_articles = JSON.parse(localStorage.getItem('saved_articles'));
  },
  methods: {
    formatLicenses(licenses) {
      let ret = '';
      if (licenses.length > 0) {
        for (let i in licenses) {
          if (i != 0) {
            ret = ret + ', ';
          }
          ret = ret + licenses[i].title;
        }
      }
      return ret;
    },
    prepareLink(links) {
      let link = null;
      if((typeof links['full-text'] != 'undefined') && (links['full-text'].url.indexOf('api.elsevier') == 0)) link = links['full-text'].url;
      if(typeof links.scopus != 'undefined') link = links.scopus.url;
      if(typeof links.display != 'undefined') link = links.display.url;
      if(typeof links.reader != 'undefined') link = links.reader.url;
      if(typeof links.doi != 'undefined') link = links.doi.url;
      if(typeof links.fulltext != 'undefined') link = links.fulltext.url;

      return link;
    },
    copyLink(link) {
      
      const el = document.createElement('textarea');
      el.value = link;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
      this.$notify({
        type: 'success',
        group: 'leita',
        duration: 5000,
        title: 'copied',
        text: 'The link was capied'
      });
    },
    calculateScore() {
      if (this.post.articleClassification.length > 0) {
        var stemmedWc = 0;
        var result;
        for (let index = 0; index < this.post.articleClassification.length; ++index) {
          var element = this.post.articleClassification[index];
          if (element.stemmed_wc > stemmedWc) {
            stemmedWc = element.stemmed_wc;
            result = element.result;
          }
        }
        this.score = stemmedWc + " " + result;
      }
    }
  }
}
</script>