<template>
  <div>

    <div class="filter-panel" style="z-index: 12">

      <div class="filter-icon" v-on:click="switchDrawer()">
        <v-img class="mx-auto d-none d-md-block" max-height="25" max-width="25"

               v-bind:src="(!drawer)? require('@/assets/images/filter_open.png'): require('@/assets/images/filter_close.png')"
        >
        </v-img>
        <v-icon color="#FFF" class=" text-center d-block d-md-none">
             mdi-tune 
          </v-icon>

        <p class="text-center" style="font-size: 14px;color: white; font-weight: bold">
          Filter
        </p>
      </div>




    </div>
    <form v-if="searchFilterForm!=null" id="searchFilterForm" ref="searchFilterForm" method="get"
          action="/search" @submit="submit">

      <div style="position:fixed; padding-left: 50px; z-index: 11">
        <v-navigation-drawer
            v-model="drawer"
            width="290"
            bottom
            style="z-index: 11; height: 100vh;"
            
            temporary

        >
          <v-list
              nav
              dense
              class="pt-10"
          >
            <v-autocomplete
                return-object
                solo
                clearable
                outlined
                class="filter-autocomplete"
                :items="filterFieldsItems"
                v-model="searchFilterForm.filterFieldsValue"
                dense
                label="All fields"

            ></v-autocomplete>

            <v-text-field
                class="filter-autocomplete"
                dense
                v-model="searchFilterForm.searchValue"
                label="Search term"
                outlined
            ></v-text-field>
            <v-row class="filter-radio">

              <v-col cols="4">
                <p style="font-size: 12px; font-weight: 600"> Add with</p>
              </v-col>
              <v-col cols="8" style="position:relative; right:10px; padding-top: 0px; padding-bottom: 0px;">
                <v-radio-group
                    v-model="searchFilterForm.withParam"
                    row
                >
                  <v-radio

                      label="AND"
                      value="and"
                  ></v-radio>
                  <v-radio
                      label="OR"
                      value="or"
                  ></v-radio>
                  <v-radio
                      label="NOT"
                      value="not"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs2 }">
                <v-text-field
                    class="filter-autocomplete"
                    outlined
                    dense
                    v-model="searchFilterForm.year"
                    :error-messages="yearErrors"
                    label="Year of publishing"
                    append-icon="mdi-calendar"
                    v-bind="attrs2"
                    v-on="on"
                    v-on:click:append="menu=true"
                    @keypress="isNumber($event)"

                ></v-text-field>
                <div v-if="alert.validationErrors && alert.validationErrors['year']"
                     :class="`alert ${alert.type}`">{{ alert.validationErrors['year'][0] }}
                </div>
              </template>
                           <v-date-picker
                                ref="picker"
                                v-model="date"
                                no-title
                                minimum-view="year" 
                                :active-picker.sync="activePicker"
                                :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 4)"
                                min="1950-NAN-NAN"
                                @click:year="save"
                            >
                            </v-date-picker>
            </v-menu>

            <!--          <v-autocomplete-->
            <!--              solo-->
            <!--              clearable-->
            <!--              outlined-->
            <!--              class="filter-autocomplete"-->
            <!--              :items="items"-->

            <!--              dense-->
            <!--              label="Databases"-->

            <!--          ></v-autocomplete>-->

            <!--          <v-autocomplete-->
            <!--              solo-->
            <!--              clearable-->
            <!--              outlined-->
            <!--              class="filter-autocomplete"-->
            <!--              :items="items"-->

            <!--              dense-->
            <!--              label="Publication type"-->

            <!--          ></v-autocomplete>-->

            <!--          <v-autocomplete-->
            <!--              solo-->
            <!--              clearable-->
            <!--              outlined-->
            <!--              class="filter-autocomplete"-->
            <!--              :items="items"-->

            <!--              dense-->
            <!--              label="Open Access"-->

            <!--          ></v-autocomplete>-->

          <!--  <v-autocomplete
                solo
                clearable
                outlined
                class="filter-autocomplete"
                :items="subjects"
                v-model="searchFilterForm.subjects"
                dense
                label="Subjects"

            ></v-autocomplete> -->
            <v-row>
              <v-col cols="6" style="padding-left: 4px;">

                <v-btn
                    block
                    class="ma-2"
                    outlined
                    small
                    dense
                    @click="showAddHistoryModal()"

                >
                  Add to History
                </v-btn>
              </v-col>
              <v-col cols="6" style="padding-right: 20px;">

                <v-btn
                    block
                    class="ma-2"
                    small
                    color="#EE7100"
                    @click="submit"
                >
                  Search
                </v-btn>
              </v-col>
            </v-row>


            <SavedSearch/>


            <!--          <v-col cols="12" class="filter-textarea">-->
            <!--            <v-btn-->
            <!--                block-->
            <!--                small-->
            <!--                dense-->
            <!--                class="ma-2 add-to-query"-->
            <!--                outlined-->
            <!--                color="#EE7100"-->
            <!--            >-->
            <!--              <v-icon-->

            <!--              >mdi-plus-->
            <!--              </v-icon>-->
            <!--              Add to query-->
            <!--            </v-btn>-->
            <!--            <v-textarea-->
            <!--                solo-->
            <!--                name="input-7-4"-->
            <!--                label="Enter/edit you search query here"-->
            <!--            ></v-textarea>-->
            <!--          </v-col>-->
          </v-list>
        </v-navigation-drawer>

      </div>
    </form>
    <SearchSaveModal :search-save-dialog-status="this.$store.state.search.showAddHistoryModal"
                     :search-filter="this.$store.state.search.searchFilterForm"/>
  </div>

</template>

<script>
import {validationMixin} from "vuelidate";
import "@/assets/css/search-filter.css";
import SearchSaveModal from "@/components/search/SearchSaveModal";
import SavedSearch from "@/components/search/SavedSearch";

const yearFieldLength = (value) => value.length > 0 ? value.length === 4 : true;
const yearFieldAboveZero = (value) => value.length > 0 ? value.charAt(0) > 0 : true;

export default {
  name: 'SearchFilter',
  components: {SavedSearch, SearchSaveModal},
  mixins: [validationMixin],
  validations: {
    searchFilterForm: {
      year: {yearFieldLength, yearFieldAboveZero}
    }
  },

  data: () => ({
    getUsers: "",
    // filterIsOpen : false,
    date: "",
    // year: "",
    drawer: false,
    menu: false,
    filterFieldsValue: '',
    activePicker: null,
    withParam: 'no',
    showFilterIcon: true,
    items: ['foo', 'bar', 'fizz', 'buzz'],
    filterFields: null,
    filterFieldsItems: [],
    subjects: [],
    searchFilterForm: null,
  }),
  computed: {
    yearErrors() {
      const errors = []
      if (!this.$v.searchFilterForm.year.$dirty) return errors
      !this.$v.searchFilterForm.year.yearFieldLength && errors.push('Year must be 4 numbers.')
      !this.$v.searchFilterForm.year.yearFieldAboveZero && errors.push('Year must start with 1 or more.')

      // !this.$v.dateField.email && errors.push('Email has incorrect format.')

      return errors
    },
    alert() {

      return this.$store.state.alert
    },

    selectedOptionType() {
      if (!this.withParam) {
        return "";
      }
      return this.withParam;
      // return this.options.find(o => o.id === this.withParam).type;
    }
  },

  mounted() {
    this.searchFilterForm = this.$store.state.search.searchFilterForm;

    this.$root.$off("set_filter_subjects");
    /*this.$root.$on("set_filter_subjects", (params) => {
      this.subjects = Object.values(params);
    }); */
    this.$root.$on("save_search", () => {
      this.showAddHistoryModal();
    });
    this.$store.dispatch('search/getFilterFields').then(
        fields => {
          if (fields) {
            // console.log(Object.entries(fields));
            this.filterFields = fields;
            this.filterFieldsItems = Object.values(fields).map(element => {
              let ret = {
                'value': element,
                'text' : element[0].toUpperCase() + element.slice(1),
              };
              
              return ret;
            });
            this.filterFieldsItems.unshift("All Fields");
            // window.location.reload(false);
          }
        },
        error => {
          console.log(error);
        });
  },

  methods: {
    isNumber: function (evt) {
      this.$v.$touch();
      if (this.searchFilterForm.year.length > 3) {
        evt.preventDefault();
        return false;
      }

      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    showAddHistoryModal: function () {
      this.$store.commit('search/setShowAddHistoryModal', {showAddHistoryModal: true})
    },


    switchDrawer: function () {
      this.drawer = !this.drawer;

    },
    save(date) {
     
     this.$refs.menu.save(date)
  
     // Reset activePicker to type YEAR
     this.$refs.picker.activePicker = 'YEAR'
  
     // Close the menu/datepicker
     this.menu = false
  
     this.year = date
     this.searchFilterForm.year = date
    },
    submit() {
      this.drawer = false;
      this.$root.$emit("submit_search", "");
    }
  },
  watch: {
    menu(val) {
      val && this.$nextTick(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
    withParam(val) {
      this.searchFilterForm.withParam = val;
    },
    searchFilterForm: {
      handler(val) {
        this.$root.$emit("filter_form_data", val);
        // do stuff
      },
      deep: true
    },
    '$store.state.search.searchFilterForm': function () {
      this.searchFilterForm = this.$store.state.search.searchFilterForm;
    }
  },
}
</script>