<template>
  <v-container v-bind:class="getContainerClass()" class="pt-5">
    <v-row no-gutters v-bind:class="getMainRowClass()">


      <v-col cols="4" md="2">
        <a href="/">
          <v-img height="37" width="119"
                 v-bind:src="getLogo()">
          </v-img>
        </a>
      </v-col>
      
      <v-col v-bind:class="getHeaderClass()">
        <v-row class='d-flex justify-end pr-md-0 pr-sm-1'>
          <Dashboard></Dashboard>
          <LoginLinks></LoginLinks>
        </v-row>
      </v-col>
      <v-col cols="2" class="d-none d-md-block">
      </v-col>
    </v-row>
    <SetNewPasswordDialogOld></SetNewPasswordDialogOld>
    <NotificationModalDialog></NotificationModalDialog>

  </v-container>
  <!-- <router-view /> -->
  <!-- <dashboard-core-footer /> -->
</template>
<script>
import Dashboard from "@/components/home/Dashboard";
import LoginLinks from "@/components/auth/LoginLinks";
//import SavedArticles from "@/components/home/SavedArticles";
import SetNewPasswordDialogOld from "@/components/auth/SetNewPasswordOld";
import NotificationModalDialog from "@/components/notification/NotificationModal";

export default {
  name: 'TrapHeaderView',
  props: {
    SearchResultsPage: Boolean,
    type: String,
  },

  methods: {
    getContainerClass() {
      switch (this.type) {
        case "Dashboard":
        case "SearchResultsPage":
          return "container--fluid";
        default:
          return "";
      }
    },

    getMainRowClass() {
      switch (this.type) {
        case "SearchResultsPage":
          return "search pl-10";
        case "Dashboard":
          return "search pl-md-10 pl-xl-10 pl-lg-10"
        default:
          return "";
      }
    },

    getLogo() {
      switch (this.type) {
        case "Dashboard":
        case "SearchResultsPage":
          return require('@/assets/images/Leita_blue.png');
        default:
          return require('@/assets/images/Leita_white.png');
      }
    },

    getHeaderClass() {
      switch (this.type) {
        case "SearchResultsPage":
          return "pt-6 offset-md-5 offset-2 offset-sm-1 col-sm-7 col-md-4 col-6 ";
        case "Dashboard":
          return "pt-6 offset-md-8 offset-2 col-sm-6 col-md-3 col-5 pr-0 pr-md-10";
        default:
          return "pt-6 offset-md-6 offset-2 col-sm-6 col-md-4 col-5";
      }
    }
  },


  computed: {
    userLogin: function () {

      return this.$store.state.authentication.status.loggedIn;
    },
  },

  mounted() {
    var modal = this.$route.query.modal;
    switch (modal) {
      case "passwordChange":
        this.$router.push({name: 'SetNewPassword', params: {token: this.$route.query.token}});
        break;
    }
    // document.body.classList.add('landing-page')
  },
  components: {
    NotificationModalDialog,
    SetNewPasswordDialogOld,
    LoginLinks,
    Dashboard,
    //SavedArticles
    //   DashboardCoreFooter: () => import('./Footer'),
  },
}
</script>
