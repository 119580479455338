<template>
    <v-card class="search_progress" elevation="12" width="140" v-if="show_progress">
        <v-navigation-drawer floating="" permanent="">
            <template v-slot:prepend="">
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            Search status
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <hr/>
                <div  v-bind:key="item.id" v-for="item in services" >
                    <div v-if="item.active">
                        <v-list-item v-if="item.show">
                            <v-list-item-content class="pa-0">
                                <v-list-item-subtitle>
                                  {{item.name}}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-icon class="ma-0 align-self-center">
                                <v-progress-linear :size="20" color="primary"  indeterminate v-if="item.status !='finished' && item.status !='failed'">
                                </v-progress-linear>
                                <v-icon color="#009933" v-if="item.status=='finished' || item.status=='failed'">mdi-check</v-icon>
                                <!--<v-icon color="#ef1313" v-if="item.status=='failed'">mdi-alert-circle</v-icon> -->
                            </v-list-item-icon>
                        </v-list-item>
                    </div>
                </div>
            </template>
            <v-divider>
            </v-divider>
        </v-navigation-drawer>
    </v-card>
</template>
<script>
    export default {
      data() {
        return {
          services:[],
          show_progress:true,
          count:0,
        }
      },
      created() {
        this.getServices();
        this.loadData();  
      },
      computed: {
        formState () {
          return this.$store.state.search.searchForm;
        }
    },
      methods: {
        loadData() {
          let searchId = this.$store.state.search.searchForm.searchId;
          if (typeof(searchId) == 'undefined') {
            return '';
          }

          this.$store.dispatch('search/getSearchProgress', searchId)
          .then((data) => {
            let i = 0;
            this.count++;
            if(data.length == 0) {
              return '';
            }
            for (let index in this.services) {
              if(typeof(data[this.services[index].id]) == 'undefined') {
                continue;
              }
              this.services[index].status = data[this.services[index].id];
              if(this.services[index].status == 'finished' || this.services[index].status == 'failed'){
                this.services[index].show = false;
                i++;
              }
            }
            if(i == this.services.length || this.count == 3) {
                this.show_progress = false;  
            } else {
                setTimeout(()=> { this.loadData()}, 10000);
                
            }
          }); 

        },
        clearProgresss () {
          for (let index in this.services) {
            this.services[index].status = 'new';
            this.services[index].show = true;
            this.count = 0;
          }
        },
        getServices () {
          this.$store.dispatch('search/getSearchServices')
          .then((data) => {
            
            this.services = data;
          });  
        },
      },
      watch: {
        formState () {
          this.clearProgresss();
          this.loadData();  
        }
      },
    }
</script>