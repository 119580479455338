<template>
  <div>
    <v-col cols="12">


      <v-row>
        <v-col cols="4" style="padding: 0px;">
          <span style="font-weight: 800">History</span>
        </v-col>
      </v-row>



      <div style="margin-top: 20px;" v-if="savedSearch!=null && savedSearch.length > 0">
        <v-row v-for="(item, index) in savedSearch" :key="item.id">
          <v-col cols="1" style="padding: 0px;">
            <span style="font-weight: 800; font-size: 13px;">#{{ index + 1 }}.</span>
          </v-col>
          <v-col cols="5" style="padding: 0px; padding-left: 5px; padding-right: 2px;">
            <span style="font-weight: 800; color: #8D8D8D; font-size: 13px; word-wrap: break-word;">{{
                item.title
              }}</span>
          </v-col>
          <v-col cols="4" style="padding: 0px;padding-right: 0px;">
            <span style="font-weight: 800; color: #8D8D8D; font-size: 13px;">{{
                new Date(item.created_at * 1000) | moment("DD-MM-YYYY")
              }}</span>
          </v-col>
          <v-col cols="1" style="padding: 1px;" class="pt-0">

            <v-icon @click="applySavedSearch(item)"  color="#206297" small>
                mdi-open-in-new
            </v-icon>


          </v-col>

          <v-col cols="1" style="padding: 1px;" class="pt-0">

          
            <v-icon @click="removeSavedSearch(item)"  color="#206297" small>
                mdi-trash-can
            </v-icon>
            


          </v-col>
        </v-row>
      </div>
      <v-row v-if="totalPages > 1" class="mb-15 mb-md-5 mb-lg-5 mb-xl-5">
        <v-col cols="12">
          <v-pagination
              v-model="page"
              color="#EE7100"
              :light="true"
              class="my-4"
              :length="totalPages"
              @input="getSavedSearch()"
          ></v-pagination>
        </v-col>
      </v-row>

    </v-col>
  </div>
</template>

<script>

export default {
  name: 'SavedSearch',
  data: () => ({
    savedSearch: null,
    page: 1,
    totalPages: 1,
  }),
  computed: {
    userLogin: function () {
      return this.$store.state.authentication.status.loggedIn;
    }
  },
  mounted() {
    this.$root.$off("get_saved_search");
    this.$root.$on("get_saved_search", () => {
      this.getSavedSearch();
    });
    this.getSavedSearch();
  },
  methods: {
    getSavedSearch() {
      if(this.userLogin) {
        this.$store.dispatch('search/getSavedSearch', this.page).then(
            response => {
              if (response.searches) {
                Object.keys(response.searches).forEach((key) => {
                  response.searches[key].search_detail = JSON.parse(response.searches[key].search_detail);
                });
                console.log(this.page);
                this.totalPages = response.totalPages;
                if (response.page > 1) {
                  this.page = response.page;
                }

              }
              this.savedSearch = response.searches;
            },
            error => {
              console.log(error);
            });
      }
    },
    applySavedSearch(item) {

      // this.$store.commit('search/setSearchFilter', {searchFilterForm: item.search_detail.searchFilterForm});
      this.$root.$emit("filter_form_data", item.search_detail.searchFilterForm);

      this.$root.$emit("set_search_string", item.search_detail.searchString);
      this.$root.$emit("submit_search");

    },
    async removeSavedSearch(item) {
      let res = await this.$confirm('Do you really remove this item?', {title: 'Warning', color: '#EE7100', icon: ''})
      if (res) {
        this.$store.dispatch('search/removeSavedSearch', item.id).then(
            response => {

              if (response.result) {
                this.getSavedSearch();
              }
            },
            error => {
              console.log(error);
            });
      }



      console.log(item);
    }
  }
}
</script>