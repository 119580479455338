const SaveArticleMixin = {
    data: () => ({
        saved_articles : {
            articles : [],
            pagination : {
                totalPages : 1,
                page       : 1,
            },
        },
    }),
    methods: {
        loadArticles() {
          this.$store.dispatch('users/getUserArticle', this.saved_articles.pagination.page).then(
              response => {
                if (response.article) {
                  Object.keys(response.article).forEach((key) => {
                    response.article[key].article_details = JSON.parse(response.article[key].article_details);
                  });

                }
                this.saved_articles.articles = response.article;
                
                this.totalPages = response.totalPages;
                if (response.page > 1) {
                  this.page = response.page;
                }
              },
              error => {
                if (error.response.status !== 403) {
                  this.$notify({
                    type: 'error',
                    group: 'leita',
                    duration: 10000,
                    title: 'An error has occurred',
                    text: 'Refresh the page and try again!'
                  });
                }
              }
          )
        },
        saveArticle(article) {
            this.$store.dispatch('users/saveArticle', article)
                .then((data) => {
                    this.saved_articles.articles = data;
                });
        },
        async removeArticle(id) {
          let res = await this.$confirm('Are you sure you want to remove this article?', {title: 'Warning', color: '#EE7100', icon: ''})
          if (res) {
            await this.$store.dispatch('overlay/show');
            this.$store.dispatch('users/removeSavedArticle', id).then(
                response => {
                  if (response.result) {
                    this.loadArticles();
                  }
                },
                error => {
                  if (error.response.status !== 403) {
                    this.$notify({
                      type: 'error',
                      group: 'leita',
                      duration: 10000,
                      title: 'An error has occurred',
                      text: 'Refresh the page and try again!'
                    });
                  }
                }).finally(() => this.$store.dispatch('overlay/hide', "", {root: true}));

          }
        }    
    }
}

export default SaveArticleMixin;