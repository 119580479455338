<template>
  <v-dialog
      persistent
      max-width="500"
      v-model="notificationModal"
  >
    <template>
      <v-card>
        <v-toolbar
            color="#68b7df"
            dark
        >{{ title }}
        </v-toolbar>
        <v-card-text>
          <v-container>

            <v-row>
              <v-col cols="12">
                <p v-html="body"></p>

              </v-col>


            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
              text
              @click="notificationModal=false"
          >Close
          </v-btn>



        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
export default {
  name: "NotificationModal",
  data: () => ({
    notificationModal: false,
    title: "",
    body: ""
  }),
  mounted() {
    this.$root.$off("show_notification_modal");
    this.$root.$on("show_notification_modal", (params) => {
      this.title = params["title"];
      this.body = params["body"];
      this.notificationModal = true;
    });
    this.$root.$on("hide_notification_modal", () => {
      this.$router.push('/');
      this.notificationModal = true;
    })
  },
}
</script>