<template>
  <div>

    <v-row justify="center">
      <v-dialog
          v-model="searchSaveDialogStatus"
          persistent
          max-width="600px"
      >

        <v-card>
          <form >
            <v-card-title>
              <span class="text-h5">Save search</span>
            </v-card-title>
            <v-card-text >
              <v-overlay :value="$store.state.overlay.show" :absolute="true">
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>
              </v-overlay>
              <v-container v-if="userLogin">

                <v-row>

                  <v-col cols="12">
                    <v-text-field
                        v-model="searchForm.title"
                        label="Title*"
                        :error-messages="titleErrors"
                        v-on:keyup.enter="saveSearch"
                    ></v-text-field>
                  </v-col>

                </v-row>
              </v-container>
              <v-container v-if="!userLogin">
                 <v-row>
                   <v-col cols="12"> 
                    Please log in/register to use this function
                   </v-col>
                 </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="closeModal()"
              >
                Close
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="saveSearch()"
                  v-if="userLogin"
              >
                Save
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  to="/login" 
                  v-if="!userLogin"
              >
                Log in/Register
              </v-btn>
            </v-card-actions>
          </form>

        </v-card>
      </v-dialog>
    </v-row>

  </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  name: 'SearchSaveModal',
  mixins: [validationMixin],
  data() {
    return {

      visableModal: false,
      searchForm: {
        title: '',
        search_detail: {
          searchString: '',
          searchFilterForm: null
        }
      },


    };
  },
  validations: {
    searchForm: {
      title: {required}
    },
  },
  props: {
    searchSaveDialogStatus: {
      type: Boolean,
      required: true,
    },
    searchFilter: {
      type: Object,
    },
    modalTitle: String,
  },

  // watch: {
  //   searchSaveDialogStatus(newValue) {
  //     console.log(newValue);
  //     this.visableModal = newValue;
  //   }
  // },
  methods: {
    closeModal() {
      this.$store.commit('search/setShowAddHistoryModal', {showAddHistoryModal: false})

    },
    async saveSearch() {


      this.$v.$touch();
      if (this.$v.searchForm.title.$pending || this.$v.searchForm.title.$error) return;

      await this.$store.dispatch('overlay/show');
      await this.$store.dispatch('alert/clear');
      this.searchForm.search_detail.searchFilterForm = this.$store.state.search.searchFilterForm;
      this.searchForm.search_detail.searchString = this.$store.state.search.searchForm.searchString;

      this.$store.dispatch('search/saveSearch', this.searchForm).then(
          response => {
            if (response.result) {
              // this.$user.set({role: response.user.role});
              // window.location.reload(false);
              // let body = this.successRegistrationHtml.replace("{email}", this.registerForm.username);
              this.$v.searchForm.$reset();
              // this.registerForm = Object.assign({}, initRegistrationForm);
              // this.showRegisterForm = false;
              this.$store.dispatch('overlay/hide');
              this.closeModal();

              this.$root.$emit("show_notification_modal", {
                'title': "Save search",
                "body": "Your search has been saved"
              });

              this.$root.$emit("get_saved_search");
            } else {
              console.log(response);
            }
          },
          error => {
            console.log(error);
          });
    },
  },
  computed: {
    titleErrors() {
      const errors = []

      if (!this.$v.searchForm.title.$dirty) return errors
      !this.$v.searchForm.title.required && errors.push('Title field is required.')

      return errors
    },
    userLogin: function () {
      return this.$store.state.authentication.status.loggedIn;
    },
  }

}
</script>