<template>

  


    <v-col cols="auto" style="padding: 0;" class="ml-1">


        <router-link v-if="!userLogin"  to="/login" class="routerLink">
           <v-icon color="#FFF">
                                           mdi-account-circle
                                        </v-icon>  Log in/Register </router-link>

        <a v-if="userLogin"  @click="logout"
           href="#" class="routerLink float-right float-sm-none">
           <v-icon color="#FFF">
                                           mdi-logout
                                        </v-icon>Logout
        </a>


        <router-link v-if="userLogin"  to="/dashboard/profile"
            href="#" class="routerLink float-right float-sm-none pl-1">
           <v-icon color="#FFF">
                                           mdi-account-circle
                                        </v-icon>Profile</router-link>

                                      </v-col>
                                    </template>
<script>

export default {
  name: "LoginLinks",
  computed: {
    userLogin: function () {
      return this.$store.state.authentication.status.loggedIn;
    },
  },

  methods: {

    logout: function () {
      this.$store.dispatch('authentication/logout');
      this.$user.set({role: "guest"});
      this.$router.push('/');
    }
  },


}
// <!--                  @click="dialog.value = false"-->

</script>