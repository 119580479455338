const PrepareAuthorsMixin = {
    methods: {
        formatAuthors(authors) {
            let ret = '';
            if (authors.length > 0) {
                for (let i in authors) {
                    if (i != 0) {
                        ret = ret + ', ';
                    }
                    if (authors[i].name.indexOf(',') > 0) {
                        let tmp = authors[i].name.split(',');
                        ret = ret + tmp[1] + ' ' + tmp[0];
                    } else {
                        ret = ret + authors[i].name;
                    }
                }
            }
            return ret;
        }
    }
}

export default PrepareAuthorsMixin