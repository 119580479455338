
const RightTop300x600 = { adUnit: '/21806670553/Leita_RightTop_300x600', width: 300, height: 600, divId: 'div-gpt-ad-1651757382671-0', slot: null, interval: null };
const RightTop300x250 = { adUnit: '/21806670553/LEITA_RightTop_300x250', width: 300, height: 250, divId: 'div-gpt-ad-1651756050444-0', slot: null, interval: null };
const RightBottom300x250 = { adUnit: '/21806670553/LEITA_RightBottom_300x250', width: 300, height: 250, divId: 'div-gpt-ad-1651755932325-0', slot: null, interval: null};
const Right160x600 = { adUnit: '/21806670553/LEITA_RightBar_160x600', width: 160, height: 600, divId: 'div-gpt-ad-1651756215424-0', slot: null, interval: null };
const Top728x90 = { adUnit: '/21806670553/LEITA_MobileBottom_728x90', width: 728, height: 90, divId: 'div-gpt-ad-1651756287381-0', slot: null, interval: null };
const Mobile320x50 = { adUnit: '/21806670553/LEITA_MobileBottom_320x50', width: 320, height: 50, divId: 'div-gpt-ad-1651756349185-0', slot: null, interval: null };
const AllAds = [RightTop300x600, RightTop300x250, RightBottom300x250, Right160x600, Top728x90, Mobile320x50] ;

const AdsMixin = {
    
    mounted() {
        window.addEventListener('resize', this.fnRunAds);
    },
    unmounted() {
        window.removeEventListener('resize', this.fnRunAds);
    },
    methods: {
        initAds() {
            const adsDiv = document.getElementById('ads');
            const visibleAds = adsDiv ? this.getVisibleAdsForWindowSize() : [];
            window.googletag = window.googletag || {cmd: []};
            visibleAds.forEach(ad => {
                this.fnDefineSlot(ad);
            });
            setTimeout(() => {this.fnRunAds(); }, 1000);       
        }, 
        
        getVisibleAdsForWindowSize() {
            // mobile
            if (window.matchMedia('(max-width:568px)').matches) {
                return [Mobile320x50];
            }
         
            // custom - portrait
            if (window.matchMedia('(min-width:569px) and (max-width:727px)').matches) {
                return [Right160x600];
            }
         
            // custom - landscape
            if (window.matchMedia('(min-width:728px) and (max-width:960px)').matches) {
                return [Top728x90];
            }
         
            // desktop - tall
            if (window.matchMedia('(min-width:961px) and (min-height:800px)').matches) {
                return [RightTop300x600, RightBottom300x250];
            }
         
            // desktop - short
            if (window.matchMedia('(min-width:961px) and (min-height:450px) and (max-height:799px)').matches) {
                return [RightTop300x250, RightBottom300x250];
            }
         
            // desktop - extra short
            if (window.matchMedia('(min-width:961px) and (min-height:200px)').matches) {
                return [RightTop300x250];
            }
            return [];
        },
        fnDefineSlot(ad) {
            if (ad.slot) {
                return;
            }
            const div = document.getElementById(ad.divId);
            if (!div) {
                return;
            }
            /*if (div.childElementCount > 1) {
         
            }*/
            window.googletag.cmd.push(function () {
                ad.slot = window.googletag.defineSlot(ad.adUnit, [ad.width, ad.height], ad.divId);
                ad.slot.addService(window.googletag.pubads());
                window.googletag.pubads().enableSingleRequest();
                window.googletag.enableServices();
            });
        },
        fnStartShowingAd(ad) {
            window.googletag.cmd.push(function () { window.googletag.display(ad.divId); });
        },
        fnStartRefreshingAd(ad) {
            if (!ad.interval) {
                ad.interval = setInterval(() => {
                    
                    !document.hidden && window.googletag.pubads().refresh([ad.slot]);
                }, 60000);
            }
        },
        fnStopRefreshingAd(ad) {
            if (ad.interval) {
                clearInterval(ad.interval);
                ad.interval = null;
            }
        },
        fnDestroySlot(ad) {
            if (ad.slot && window.googletag.destroySlots([ad.slot])) {
                ad.slot = null;
            }
        },
        fnRunAds(){
            
            const adsDiv = document.getElementById('ads');
            const visibleAds = adsDiv ? this.getVisibleAdsForWindowSize() : [];
            visibleAds.forEach(ad => {
                this.fnDefineSlot(ad);
                this.fnStartShowingAd(ad);
                this.fnStartRefreshingAd(ad);
            });
            AllAds.forEach(ad => {
                const isVisible = visibleAds.indexOf(ad) >= 0;
                const adDiv = document.getElementById(ad.divId);
                adDiv.hidden = !isVisible;
                if (!isVisible) {
                    this.fnStopRefreshingAd(ad);
                    this.fnDestroySlot(ad);
                }
            });
        }
    }
}
 

export default AdsMixin;
